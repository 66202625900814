/* ============================ */
/*            Mail Box
/* ============================ */

.mail_content {
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  .fa.fa-star {
    color: theme-color-main(warning);
  }
  .mail_list {
    .label-primary,
    .label-secondary,
    .label-danger,
    .label-warning,
    .label-success {
      @media screen and (max-width: 991px) {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }
  .all_mails_btn {
    @media screen and (max-width: 991px) {
      float: none !important;
      margin-top: 20px;
    }
  }
}
//Read Mail
.recipient {
  font-size: 13px;
  margin-top: 5px;
  color: #666;
  font-weight: 600;
}
.mailbox-time {
  font-size: 11px;
  color: #868383;
  font-weight: 500;
}
.reply_btn {
  @media screen and (max-width: 991px) {
    float: none !important;
  }
}

// Attachment

.mailbox-attachments {
  li {
    float: left;
    width: 200px;
    border: 1px solid #eee;
    margin-bottom: 10px;
    margin-right: 10px;
    .btn-primary {
      position: relative;
      overflow: hidden;
      height: 20px;
      width: 25px;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 12px;
      }
    }
  }
  .mailbox-attachment-name {
    font-weight: bold;
    color: #666;
  }
  .mailbox-attachment-icon,
  .mailbox-attachment-info,
  .mailbox-attachment-size {
    display: block;
  }
  .mailbox-attachment-info {
    padding: 10px;
    background: #f4f4f4;
  }
  .mailbox-attachment-size {
    color: #999;
    font-size: 12px;
  }
  .mailbox-attachment-icon {
    text-align: center;
    font-size: 65px;
    color: #666;
    padding: 20px 10px;
  }
  .mailbox-attachment-icon.has-img {
    padding: 0;
  }
  .mailbox-attachment-icon.has-img > img {
    max-width: 100%;
    height: auto;
  }
}

.label-primary,
.label-secondary,
.label-danger,
.label-warning,
.label-success {
  padding: 5px;
  background: theme-color-main(primary);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
}
.label-secondary {
  background: theme-color-main(secondary);
}
.label-success {
  background: theme-color-main(success);
}

.label-warning {
  background: theme-color-main(warning);
}

.label-danger {
  background: theme-color-main(danger);
}
.mail_tabs {
  margin-top: 30px;
  border: 1px solid #eaeaea;
  border-radius: 9px;
  overflow: hidden;
  li {
    display: block;
    a {
      display: block;
      color: #555555;
      padding: 10px 15px;
      text-decoration: none;
      background-color: #ffffff;
      margin-bottom: 1px;
      i {
        font-size: 14px;
        margin-right: 10px;
      }
    }
    &.active a, a:hover {
      background-color: #f5f5f5;
      color: theme-color-main(primary);
    }
  }
}
.inbox_numb {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-left: 5px;
}
.mail_head {
  display: inline-block;
  width: auto;
  float: left;
  margin: 0;
  font-size: 20px;
}
.mail_more_btn, .mail_nav, .mail_count_nav {
  margin: 30px 0;
}
.mail_list table tr td:nth-child(4) {
  font-size: 95%;
}
.mail_list table tr td:nth-child(5) {
  font-size: 85%;
}
.mail_list table tr.unread td:nth-child(3) {
  font-weight: 700;
}
.mail_list table tr td:nth-child(3), .mail_list table tr td:nth-child(4), .mail_list table tr td:nth-child(5), .mail_list table .star {
  cursor: pointer;
}

/* ============================ */
/*            End Mailbox
/* ============================ */