/* ============================ */
/*            Table
/* ============================ */

.table {
  font-size: 14px;
  th {
    font-weight: 600;
  }
  i {
    margin-right: 0;
  }
}

table {
  .progress {
    height: 8px;
  }
}

.table-primary,
.table-primary>td,
.table-primary>th {
  background-color: lighten(theme-color-main(primary), 30%) !important;
  color: $white;
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: lighten(theme-color-main(success), 30%) !important;
}
.table-warning,
.table-warning>td,
.table-warning>th {
  background-color: lighten(theme-color-main(warning), 30%) !important;
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
  background-color: lighten(theme-color-main(secondary), 30%) !important;
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: lighten(theme-color-main(danger), 30%) !important;
}

.table-dark,
.table-dark>td,
.table-dark>th {
  background-color: lighten(theme-color-main(dark), 30%) !important;
  color: $white;
}

.table-light,
.table-light>td,
.table-light>th {
  background-color: lighten(theme-color-main(light), 30%) !important;
}

.single-table .table {
  margin-bottom: 0;
}

table tr th {
  border-bottom: none;
}

table tr th,
table tr td {
  vertical-align: middle !important;
  padding: 15px 10px !important;
  font-size: 12px;
  font-weight: 500;
  .badge {
    font-size: 12px;
  }
}
.table .thead-light th {
  background-color: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.03);
}

.data-tables table tr th,
.data-tables table tr td {
  border-top: 1px solid rgba(120, 130, 140, 0.13) !important;
}

table tr td [class^="ti-"] {
  cursor: pointer;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}

// Data Table Styling

.data-tables {
  font-size: $default-font-size;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13) !important;
}



@media screen and (max-width: 767px) {
  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    top: 3px !important;
    left: 44% !important;
    height: 10px !important;
    width: 10px !important;
    line-height: 10px !important;
  }
  table.dataTable {
    width: 100%;
  }
}

.dataTables_wrapper .dataTable thead .sorting:before, .dataTables_wrapper .dataTable thead .sorting_asc:before, .dataTables_wrapper .dataTable thead .sorting_asc_disabled:before, .dataTables_wrapper .dataTable thead .sorting_desc:before, .dataTables_wrapper .dataTable thead .sorting_desc_disabled:before {
  content: "\f3d7";
  right: 17px;
  font-family: Ionicons;
}

.dataTables_wrapper .dataTable thead .sorting:after, .dataTables_wrapper .dataTable thead .sorting_asc:after, .dataTables_wrapper .dataTable thead .sorting_asc_disabled:after, .dataTables_wrapper .dataTable thead .sorting_desc:after, .dataTables_wrapper .dataTable thead .sorting_desc_disabled:after {
  content: "\f3d4";
  right: 7px !important;
  font-family: Ionicons;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc{
  background-image: none !important;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 76px;
  margin: 0 10px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: transparent !important;
  border-color: transparent !important;
}

/* datatable-primary */

.datatable-primary thead {
  background: theme-color-main(primary);
  color: #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: none;
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  border: none;
  margin-top: 20px;
}

table.dataTable.no-footer {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13) !important;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom-color: transparent;
}

.datatable-primary .dataTables_paginate .page-item.active .page-link,
.datatable-primary .dataTables_paginate .page-item .page-link:hover {
  background-color: theme-color-main(primary);
  border-color: theme-color-main(primary);
  color: #fff;
}

.datatable-primary .dataTables_paginate .page-link {
  color: theme-color-main(primary);
  border: 1px solid theme-color-main(primary);
}

.datatable-primary .dataTables_paginate .paginate_button.disabled,
.datatable-primary .dataTables_paginate .paginate_button.disabled:hover,
.datatable-primary .dataTables_paginate .paginate_button.disabled:active {
  color: theme-color-main(primary)!important;
  border: none;
}

.datatable-primary .dataTables_paginate .page-item.disabled .page-link {
  color: #9f98f7;
  background-color: #f9f9f9;
  border-color: #c9c6f5;
}



/* datatable-dark */

.datatable-dark thead {
  background: #444;
  color: #fff;
}

.datatable-dark .dataTables_paginate .page-item.active .page-link,
.datatable-dark .dataTables_paginate .page-item .page-link:hover {
  background-color: #444;
  border-color: #444;
  color: #fff;
}

.datatable-dark .dataTables_paginate .page-link {
  color: #444;
  border: 1px solid #444;
}

.datatable-dark .dataTables_paginate .paginate_button.disabled,
.datatable-dark .dataTables_paginate .paginate_button.disabled:hover,
.datatable-dark .dataTables_paginate .paginate_button.disabled:active {
  color: #444!important;
  border: none;
}

.datatable-dark .dataTables_paginate .page-item.disabled .page-link {
  color: #999;
  background-color: #f9f9f9;
  border-color: #999;
}

// Js Grid

.jsgrid {
  .jsgrid-pager {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 4px;
    line-height: 40px;
    .page-item .page-link,
    .jsgrid-pager-nav-button .page-link,
    .jsgrid-pager-page .page-link,
    .jsgrid-pager-nav-button a,
    .jsgrid-pager-page a {
      border-color: #f2f2f2;
      font-size: 14px;
      font-weight: 400;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
    .jsgrid-pager-nav-button a,
    .jsgrid-pager-page a {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: theme-color-main(primary);
      background-color: #fff;
      border: 1px solid #dee2e6;
    }
  }
  .jsgrid-pager-page,
  .jsgrid-pager-nav-button{
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
  .jsgrid-pager-current-page {
    padding: 9px 15px !important;
    font-weight: initial;
    line-height: initial;
    margin-left: 10px;
    background: theme-color-main(primary);
    color: #fff;
    height: 35px;
    margin-top: 3px;
    border-radius: 4px;
  }
  .jsgrid-mode-button {
    background-image: url("../../images/js-grid.png") !important;
  }
  .jsgrid-button {
    background-image: url("../../images/js-grid.png") !important;
  }
  .jsgrid-edit-row input[type='text'],
  .jsgrid-edit-row input[type='number'],
  .jsgrid-edit-row input[type='password'],
  .jsgrid-edit-row input[type='tel'],
  .jsgrid-edit-row input[type='email'],
  .jsgrid-edit-row select,
  .jsgrid-edit-row textarea,
  .jsgrid-filter-row input[type='text'],
  .jsgrid-filter-row input[type='number'],
  .jsgrid-filter-row input[type='password'],
  .jsgrid-filter-row input[type='tel'],
  .jsgrid-filter-row input[type='email'],
  .jsgrid-filter-row select,
  .jsgrid-filter-row textarea,
  .jsgrid-insert-row input[type='text'],
  .jsgrid-insert-row input[type='number'],
  .jsgrid-insert-row input[type='password'],
  .jsgrid-insert-row input[type='tel'],
  .jsgrid-insert-row input[type='email'],
  .jsgrid-insert-row select,
  .jsgrid-insert-row textarea {
    width: 100%;
    padding: 8px;
    background: #fff;
    border: 1px solid #eaeaea;
  }
}

.jsgrid-header-row>.jsgrid-header-cell {
  font-weight: 600;
}

/* ============================ */
/*            End Table
/* ============================ */