/* ============================ */
/*            Loaders
/* ============================ */


// Ring Loader
.ring_loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.ring_loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 4px;
  border-radius: 50%;
  animation: ring_loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.primary_ring_loader {
  div {
    border: 4px solid theme-color-main(primary);
    border-color: theme-color-main(primary) transparent transparent transparent;
  }
}
.secondary_ring_loader {
  div {
    border: 4px solid theme-color-main(secondary);
    border-color: theme-color-main(secondary) transparent transparent transparent;
  }
}
.success_ring_loader {
  div {
    border: 4px solid theme-color-main(success);
    border-color: theme-color-main(success) transparent transparent transparent;
  }
}
.warning_ring_loader {
  div {
    border: 4px solid theme-color-main(warning);
    border-color: theme-color-main(warning) transparent transparent transparent;
  }
}
.danger_ring_loader {
  div {
    border: 4px solid theme-color-main(danger);
    border-color: theme-color-main(danger) transparent transparent transparent;
  }
}
.dark_ring_loader {
  div {
    border: 4px solid theme-color-main(dark);
    border-color: theme-color-main(dark) transparent transparent transparent;
  }
}
.ring_loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.ring_loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.ring_loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ring_loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


// Default Loader

.default_loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.default_loader div {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: default_loader 1.2s linear infinite;
}
.default_loader div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.default_loader div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.default_loader div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.default_loader div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.default_loader div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.default_loader div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.default_loader div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.default_loader div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.default_loader div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.default_loader div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.default_loader div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.default_loader div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes default_loader {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

// Dual Ring
.loader_dual {
  display: inline-block;
  width: 50px;
  height: 50px;
  &:after {
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    margin: 8px;
    border-radius: 50%;
    animation: loader_dual 1.2s linear infinite;
  }
}
.primary_dual_loader {
  &:after {
    border: 4px solid theme-color-main(primary);
    border-color: theme-color-main(primary) transparent theme-color-main(primary) transparent;
  }
}
.secondary_dual_loader {
  &:after {
    border: 4px solid theme-color-main(secondary);
    border-color: theme-color-main(secondary) transparent theme-color-main(secondary) transparent;
  }
}
.success_dual_loader {
  &:after {
    border: 4px solid theme-color-main(success);
    border-color: theme-color-main(success) transparent theme-color-main(success) transparent;
  }
}
.warning_dual_loader {
  &:after {
    border: 4px solid theme-color-main(warning);
    border-color: theme-color-main(warning) transparent theme-color-main(warning) transparent;
  }
}
.danger_dual_loader {
  &:after {
    border: 4px solid theme-color-main(danger);
    border-color: theme-color-main(danger) transparent theme-color-main(danger) transparent;
  }
}
.dark_dual_loader {
  &:after {
    border: 4px solid theme-color-main(dark);
    border-color: theme-color-main(dark) transparent theme-color-main(dark) transparent;
  }
}
@keyframes loader_dual {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Roler Loader

.roller_loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.roller_loader div {
  animation: roller_loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.roller_loader div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: -3px 0 0 -3px;
}
.roller_loader div:nth-child(1) {
  animation-delay: -0.036s;
}
.roller_loader div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.roller_loader div:nth-child(2) {
  animation-delay: -0.072s;
}
.roller_loader div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.roller_loader div:nth-child(3) {
  animation-delay: -0.108s;
}
.roller_loader div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.roller_loader div:nth-child(4) {
  animation-delay: -0.144s;
}
.roller_loader div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.roller_loader div:nth-child(5) {
  animation-delay: -0.18s;
}
.roller_loader div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.roller_loader div:nth-child(6) {
  animation-delay: -0.216s;
}
.roller_loader div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.roller_loader div:nth-child(7) {
  animation-delay: -0.252s;
}
.roller_loader div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.roller_loader div:nth-child(8) {
  animation-delay: -0.288s;
}
.roller_loader div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes roller_loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.primary_roller_loader {
  div {
    &:after {
      background: theme-color-main(primary);
    }
  }
}
.secondary_roller_loader {
  div {
    &:after {
      background: theme-color-main(secondary);
    }
  }
}
.success_roller_loader {
  div {
    &:after {
      background: theme-color-main(success);
    }
  }
}
.warning_roller_loader {
  div {
    &:after {
      background: theme-color-main(warning);
    }
  }
}
.danger_roller_loader {
  div {
    &:after {
      background: theme-color-main(danger);
    }
  }
}
.dark_roller_loader {
  div {
    &:after {
      background: theme-color-main(dark);
    }
  }
}

// Ripple Loader

.loader_rippler {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loader_rippler div {
  position: absolute;
  border: 4px solid;
  opacity: 1;
  border-radius: 50%;
  animation: loader_rippler 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader_rippler div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loader_rippler {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.primary_ripple_loader {
  div {
    border-color: theme-color-main(primary);
  }
}
.secondary_ripple_loader {
  div {
    border-color: theme-color-main(secondary);
  }
}
.success_ripple_loader {
  div {
    border-color: theme-color-main(success);
  }
}
.warning_ripple_loader {
  div {
    border-color: theme-color-main(warning);
  }
}
.danger_ripple_loader {
  div {
    border-color: theme-color-main(danger);
  }
}
.dark_ripple_loader {
  div {
    border-color: theme-color-main(dark);
  }
}

// Spinner Loader

.spinner_loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.spinner_loader div {
  transform-origin: 32px 32px;
  animation: spinner_loader 1.2s linear infinite;
}
.spinner_loader div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
}
.spinner_loader div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.spinner_loader div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.spinner_loader div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.spinner_loader div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner_loader div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.spinner_loader div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.spinner_loader div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.spinner_loader div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.spinner_loader div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.spinner_loader div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.spinner_loader div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.spinner_loader div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes spinner_loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.primary_spinner_loader {
  div {
    &:after {
      background: theme-color-main(primary);
    }
  }
}
.secondary_spinner_loader {
  div {
    &:after {
      background: theme-color-main(secondary);
    }
  }
}
.success_spinner_loader {
  div {
    &:after {
      background: theme-color-main(success);
    }
  }
}
.warning_spinner_loader {
  div {
    &:after {
      background: theme-color-main(warning);
    }
  }
}
.danger_spinner_loader {
  div {
    &:after {
      background: theme-color-main(danger);
    }
  }
}

.dark_spinner_loader {
  div {
    &:after {
      background: theme-color-main(dark);
    }
  }
}.dark_spinner_loader {
  div {
    &:after {
      background: theme-color-main(dark);
    }
  }
}




/* ============================ */
/*            End Loaders
/* ============================ */