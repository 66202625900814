//@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/style.scss';

.App {
  height: 100vh;
  width: 100vw;
  transition-duration: $transitionDurationAnimation;
  background-color: #fafafa;
}

.application-choose {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .application-single-choice {
    border: none;
    border-radius: 4px;
    background-color: #fafafa;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.03), 0 1px 4px rgba(0, 0, 0, 0.02);
    min-width: 400px;
    display: flex;
    flex-direction: column;
  }
}