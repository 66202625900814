/* ============================ */
/*            Alert             */
/* ============================ */

.alert {
  letter-spacing: 0;
  font-size: 14px;
  border: none;
  padding: 10px 16px;
  font-weight: 600;
  span {
    font-size: 20px;
  }
}

.alert strong,
.alert-link {
  font-weight: 700;
}

.alert-primary,
.alert-danger,
.alert-warning,
.alert-dark,
.alert-info,
.alert-light,
.alert-success{
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: $white;
}

.alert-primary .alert-link,
.alert-danger .alert-link,
.alert-warning .alert-link,
.alert-info .alert-link,
.alert-success .alert-link {
  color: #fff;
  text-decoration: underline;
}

.alert-primary {
  background-color: theme-color-main('primary');
  border-left: 4px solid theme-color-main('primary');
}

.alert-success {
  border-left: 4px solid theme-color-main('success');
  background-color: theme-color-main('success');
}

.alert-danger {
  border-left: 4px solid theme-color-main('danger');
  background-color: theme-color-main('danger');
}

.alert-warning {
  border-left: 4px solid theme-color-main('warning');
  background-color: theme-color-main('warning');
}

.alert-info {
  border-left: 4px solid theme-color-main('info');
  background-color: theme-color-main('info');
}

.alert-dark {
  border-left: 4px solid theme-color-main('dark');
  background-color: theme-color-main('dark');
}

.alert-light {
  border-left: 4px solid theme-color-main('light');
  background-color: theme-color-main('light');
}

/* additional content */

.alert-heading {
  margin-bottom: 7px;
}

.alert-dismiss .alert {
  padding: 13px 15px;
}

.alert-dismiss .alert-dismissible .close {
  top: 0;
  outline: none;
  font-size: 13px;
}
.alert-primary hr {
  border-top-color: #fff;
}

/* ============================ */
/*            End Alert         */
/* ============================ */