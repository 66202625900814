.text-field-icon {
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
}

.text-field-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  transition-duration: $transitionDurationNormal;

  outline: 0;
  border: 0;
  border-radius: 2px;
  padding-right: 8px;
}

.text-field-label {
  transition-duration: $transitionDurationNormal;
  color: #999999;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 12px;
}

.text-field-action-button {
  transition-duration: $transitionDurationNormal;
  padding: 12px 24px;
  color: $colorGrey;
  border-radius: 2px;
  margin-left: 20px;
  cursor: pointer;
}

.text-field-value-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text-field-action-icon {
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.text-field-content:hover {
  background-color: $colorMainDiffused08;
}

.text-field-content:focus-within {
  background-color: $colorMainDiffused08;
}

.text-field-content-no-hover {
  @extend .text-field-content;
}

.text-field-content-no-hover:hover {
  background-color: #ffffff00;
}

.text-field-content-no-hover:focus-within {
  background-color: #ffffff00;
}

.text-field-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.text-field-input-value {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  flex: 1;
  transition-duration: $transitionDurationNormal;
  color: #333333;
  background-color: transparent;
  resize: none;
}

.text-field-display-value {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  flex: 1;
  transition-duration: $transitionDurationNormal;
  color: #333333;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  resize: none;
}

.text-field-input-value::placeholder {
  color: #999999;
}



.single-choice-field {

}

.multiple-choice-field {
  .multiple-choice-labels-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    .multiple-choice-label-container {
      background-color: #eee;
      color: #333;
      padding: 4px 10px;
      font-size: $sizeFontSmall;
      margin-top: 4px;
      margin-right: 4px;
    }
  }
}



.image-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;

  .image-field-itself {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    object-fit: cover;
    transition-duration: $transitionDurationLong;
  }
  .image-overlay {
    transition: .4s ease;
    opacity: 0;
    position: absolute;
    background-color: #ffffff99;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    .image-overlay-box {
      background-color: $colorWhite;
      color: theme-color-main('primary');
      font-size: $sizeFontSmall;
      padding: 10px 20px;
      border-radius: 100px;
    }
  }
}

.image-field:hover {
  .image-field-itself{

  }
  .image-overlay {
    opacity: 1;
  }
}


.span-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;  // <- you can change rows number
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
