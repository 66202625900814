/* ============================ */
/*          Typography          */
/* ============================ */

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $font-family;
  font-weight: 400;
  overflow-x: hidden;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family;
  line-height: normal;
  color: theme-color-main(dark);
  font-weight: 600;
}
h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: $default-font-size;
  font-weight: 400;
  line-height: 25px;
}

a {
  color: theme-color-main(primary);
  &:hover {
    color: theme-color-main(primary);
  }
}

ul {
  li {
    font-size: $default-font-size;
    font-weight: 400;
  }
}

blockquote {
  font-size: 16px !important;
  font-weight: 300;
  border: none;
  line-height: 30px;
  color: $mine-shaft;
  margin-bottom: 20px;
  font-style: italic;
  position: relative;
  margin-top: 40px;
  padding: 60px 30px 40px;
  text-align: center;
  @media only screen and (max-width: 640px) {
    padding: 30px 25px 30px 70px;
  }
  @media only screen and (max-width: 480px) {
    padding: 32px 20px;
  }
  &:before {
    content: "\e67f";
    font-family: themify;
    font-size: 45px;
    color: #e7e6e6;
    left: 50%;
    position: absolute;
    top: 3px;
    transform: translate(-50%, 0px);
    @media only screen and (max-width: 480px) {
      opacity: 0.2;
    }
  }
  span {
    position: relative;
    padding-left: 20px;
    &:before {
      content: '';
      width: 12px;
      height: 1px;
      background: $mine-shaft;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -2px;
    }
  }
}

.text-primary {
  color: theme-color-main(primary) !important;
}
.text-secondary {
  color: theme-color-main(secondary) !important;
}
.text-success {
  color: theme-color-main(success) !important;
}
.text-danger {
  color: theme-color-main(danger) !important;
}
.text-warning {
  color: theme-color-main(warning) !important;
}
.text-dark {
  color: theme-color-main(dark) !important;
}
.text-light {
  color: #d2ddeb !important;
}