.snack {
  letter-spacing: 0;
  font-size: 14px;
  border: none;
  padding: 10px 16px;
  font-weight: 600;
}

.snack-normal {
  @extend .snack;
  color: #fff;
  font-size: 14px;
  background-color: theme-color-main('success');
}

.snack-error {
  @extend .snack;
  color: #fff;
  font-size: 14px;
  background-color: theme-color-main('danger');
}

.snack-warn {
  @extend .snack;
  color: #fff;
  font-size: 14px;
  background-color: theme-color-main('warning');
}