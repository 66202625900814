/* ============================ */
/*            Modal
/* ============================ */

.modal-dialog.modal-xl {
  max-width: 100%;
}

/* ============================ */
/*            End Modal
/* ============================ */