// ========================================= //
//			Colors Used in Admin Dashboard
// ========================================= //

$white:				#FFFFFF;
$tundora:			#444444;
$Alabaster:	        #f9f9f9;
$seashell:			#F1F0F0;
$Gallery:           #eceaea;
$dove-gray:			#666666;
$mercury:			#e1e1e1;
$hint-of-red:		#FAF8F8;
$black:				#000000;
$mine-shaft:		#202020;
$shark:				#212529;
$albi: 				#fcfcfc;



// ========================================= //
//			Main Primary Colors
// ========================================= //


$theme-colors-main: (
  primary: #363f5a,
  secondary: #934A5F,
  general: #3a3247,
  success: #105324,
  info: #0d3d61,
  warning: #978107,
  danger: #741508,
  light: #eaeaea,
  dark: #444
);

// ========================================= //
//			Fonts Variables
// ========================================= //

$font-family: 'Open Sans', sans-serif;
//$font-family: 'Montserrat', sans-serif;
$default-font-size: 14px;
$body-color: theme-color-main(dark);
$title-color: theme-color-main(dark);


// ========================================= //
//			Buttons Variables
// ========================================= //

// Fixed Width
$btn-fixed-w:  110px;

// Font Sizes
$btn-font-size:       14px;
$btn-font-size-xs:    12px;
$btn-font-weight:     500;

// Border Readius
$btn-border-radius:   4px;

// Btn Padding

$btn-xs-padding-y:    8px;
$btn-xs-padding-x:    12px;

$btn-sm-padding-y:    8px;
$btn-sm-padding-x:    13px;

$btn-md-padding-y:    10px;
$btn-md-padding-x:    18px;

$btn-lg-padding-y:    15px;
$btn-lg-padding-x:    30px;

$btn-xl-padding-y:    19px;
$btn-xl-padding-x:    24px;













$colorBlackish: #020202; // rich black
$colorWhite: #ffffff; // white

$colorError: #7e0e12;
$colorWarn: #7b7a08;

$colorRequired: #7e0e12;
$coloDone: #0b411c;

$colorTransparentLightSoft: #ffffff33;
$colorTransparentLightMedium: #ffffff66;
$colorTransparentLightHard: #ffffff99;
$colorTransparentLightRock: #ffffffCC;

$colorTransparentDarkSoft: #00000033;
$colorTransparentDarkMedium: #00000066;
$colorTransparentDarkHard: #00000099;
$colorTransparentDarkRock: #000000CC;

$colorMain: #333333; // grey blue
$colorMainLight: #54484D; // light blue

$colorMainDiffused75: #54484Dcc; // grey blue 25%
$colorMainDiffused25: #54484D44; // grey blue 25%
$colorMainDiffused04: #54484D05;
$colorMainDiffused08: #54484D11;
$colorMainDiffused16: #54484D22;
$colorMainDarker: #3a3235;

$colorMainDarkOther: #2c3655;
$colorMainDarkOtherDarker: #252e47;
$colorMainDarkerRethink: #222738;
$colorGreyRethink: #494f61;
$colorMainLightRethink: #363f5a;

$colorAccent: #934A5F; // dark red
$colorAccentDarker: #531f2e; // dark red
$colorAccentLighter: #bb7588; // dark red
$colorBlend: #d2d4c8; // light nude

$colorDarkGrey: #666666;
$colorGrey: #888888;
$colorGreyLightAF: #cccccc;

$angleGradient: 90deg;

$gradientMain: linear-gradient($angleGradient, $colorMainLight 0%, $colorMainDarker 100%);
$gradientAccent: linear-gradient($angleGradient, $colorAccent 0%, $colorAccentLighter 100%);

$breakpointMain: 768px;
$xs: 768px;
$s: 992px;
$m: 1200px;
$l: 1200px;

$sizeBorderRadiusSmall: 2px;
$sizeBorderRadiusBig: 4px;
$sizeBorderRadiusHuge: 8px;

$sizeFontHuge: 32px;
$sizeFontExtraLarge: 26px;
$sizeFontLarge: 22px;
$sizeFontNormal: 14px;
$sizeFontSmall: 12px;
$sizeFontExtraSmall: 10px;

$drawerOpenSize: 140px;
$drawerClosedSize: 60px;
$drawerDifferenceSize: 80px;

$boxShadowDrawer:  4px 8px 30px 0px rgba(0,0,0,0.6);
$boxShadowToolbar:  20px 0px 30px 0px rgba(0,0,0,0.6);

$boxShadowSmall: 2px 4px 15px 0px rgba(0,0,0,0.6);
$boxShadowTiny: 1px 2px 4px 0px rgba(0,0,0,0.6);
$boxShadowInsetSmall: inset 2px 4px 15px 0px rgba(0,0,0,0.6);

$transitionDurationNormal: 0.2s;
$transitionDurationLong: 0.4s;
$transitionDurationAnimationShort: 0.4s;
$transitionDurationAnimation: 0.6s;
$transitionDurationAnimationLong: 0.8s;
