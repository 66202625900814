/* ============================ */
/*            Tabs
/* ============================ */

.nav-tabs {
  border-color: theme-color-main(primary);
  .nav-item.show .nav-link,
  .nav-link.active {
    background-color: theme-color-main(primary);
    border-color: theme-color-main(primary);
    color: $white;
  }
  .nav-link {
    color: #444;
  }
  
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover,
.nav-pills .nav-link:focus, .nav-pills .nav-link:hover {
  border-color: theme-color-main(primary);
  background-color: theme-color-main(primary);
  color: #fff;
  cursor: pointer;
}
.vertical_tabs {
  a {
    text-align: center;
    margin-bottom: 10px;
    i {
      display: block;
      margin-bottom: 6px;
    }
  }
}

/* ============================ */
/*            End Tabs
/* ============================ */