.base-modal {
  padding-top: 64px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .modal-container {
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 600px;
    min-height: 400px;
    max-height: 90%;
    max-width: 90%;
    outline: 0;
    padding: 20px;
    padding-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .modal-top-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .modal-close-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .modal-title-text {
          flex: 1;
          font-size: $sizeFontSmall;
          color: #888888;
        }
      }

    }

    .modal-content-container {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow-y: scroll;
    }

    .modal-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-top: 20px;
      padding-right: 10px;
      padding-left: 10px;
      width: 100%;

      .modal-button-more {
        transition-duration: $transitionDurationNormal;
        padding: 10px 20px;
        color: $colorGrey;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }

      .modal-button-more:hover {
        background-color: $colorMainDiffused08;
      }

      .modal-button-done {
        transition-duration: $transitionDurationNormal;
        padding: 10px 20px;
        color: $colorWhite;
        background-color: $colorAccentDarker;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }

      .modal-button-done:hover {
        background-color: $colorAccent;
      }
    }
  }
}

.image-upload-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  .upload-input {
    padding: 10px;
    font-size: $sizeFontNormal;
    color: #333;
    border-width: 0;
    background-color: #eee;
  }
  .image-container {
    width: 100%;
    padding-top: 10px;
    .image-okay {
      height: 300px;
      max-height: 300px;
      max-width: 100%;
      object-fit: contain;
    }
  }
}






.picker-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  .picker-modal-list-container {
    flex: 1;
    width: 100%;
    margin-top: 10px;
    height: 100%;
  }
}

.picker-modal-search-container {
  width: 100%;
  .picker-modal-search-input {
    width: 100%;
    font-size: $sizeFontSmall;
    color: #333333;
    border-width: 0;
    outline: 0;
    border-bottom-width: 1px;
    border-color: $colorGrey;
    padding: 10px 10px;
    transition-duration: $transitionDurationNormal;
  }
  .picker-modal-search-input:focus {
    border-color: $colorMain;
  }
}

.picker-modal-item-entry {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: $sizeBorderRadiusBig;
  cursor: pointer;
  background-color: #eeeeee;
  margin-bottom: 10px;

  .modal-checkbox-outline {
    padding: 4px;
    background-color: $colorAccent;
    margin-right: 20px;
    border-radius: 100px;

    .modal-checkbox-inside {
      flex: 1;
      background-color: $colorAccent;
      height: 20px;
      width: 20px;
      transition-duration: $transitionDurationNormal;
      border-radius: 100px;
    }

  }

  .modal-item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .modal-item-content-title {
      font-size: $sizeFontSmall;
      color: $colorMainDarker;
    }

    .modal-item-content-description {
      font-size: $sizeFontSmall;
      color: $colorDarkGrey;
      padding-top: 4px;
    }
  }
}



.normal-additional-button {
  transition-duration: $transitionDurationNormal;
  border-radius: $sizeBorderRadiusSmall;
  padding: 10px 20px;
  font-size: $sizeFontNormal;
  color: $colorGrey;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.normal-additional-button:hover {
  background-color: $colorMainDiffused08;
}











.update-modal {
  padding-top: 64px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .m-container {
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 600px;
    min-height: 240px;
    outline: 0;
    padding: 20px;
    padding-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .close-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .m-field {
      transition-duration: $transitionDurationNormal;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 20px;
      padding-bottom: 20px;
      border-radius: 4px;

      ::placeholder {
        color: $colorGrey;
      }

      .m-title {
        transition-duration: $transitionDurationNormal;
        width: 100%;
        font-size: 22px;
        color: $colorDarkGrey;
        border-width: 0;
        outline: 0;
        border-bottom-width: 1px;
        border-color: $colorGrey;
        padding: 10px 10px;
      }

      .m-title:focus {
        border-color: $colorMain;
      }

      .m-icon {
        min-width: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .m-input {
        min-width: 300px;
        transition-duration: $transitionDurationNormal;
        font-size: 16px;
        color: #333333;
        border-width: 0;
        outline: 0;
        border-bottom-width: 1px;
        border-color: $colorGrey;
        border-radius: 4px 4px 0 0;
        padding: 10px 10px;
      }

      .m-input:focus {
        border-color: $colorMain;
        background-color: $colorMainDiffused08;
      }

      .m-input:hover {
        background-color: $colorMainDiffused08;
      }

    }

    .m-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-top: 20px;
      padding-right: 10px;
      padding-left: 10px;
      width: 100%;

      .m-button-more {
        transition-duration: $transitionDurationNormal;
        padding: 10px 20px;
        color: $colorGrey;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }

      .m-button-more:hover {
        background-color: $colorMainDiffused08;
      }

      .m-button-done {
        transition-duration: $transitionDurationNormal;
        padding: 10px 20px;
        color: $colorWhite;
        background-color: $colorMain;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }

      .m-button-done:hover {
        background-color: $colorMainDarker;
      }
    }
  }
}




