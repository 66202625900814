/* ============================ */
/*            Toastr
/* ============================ */

.toast-title {
  font-weight: 700;
}

.toast-message {
  font-size: 14px;
  font-weight: 600;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  a,
  label {
    color: $white;
  }
  a{
    &:hover {
      color: #CCC;
      text-decoration: none;
    }
  }
}


.toast-close-button {
  position: relative;
  right: -.3em;
  top: -.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #FFF;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
  filter: alpha(opacity=80);
  line-height: 1;
  &:focus,
  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .4;
    filter: alpha(opacity=40);
  }
  .rtl {
    .toast-close-button {
      left: -.3em;
      float: left;
      right: .3em;
    }
  }
}


button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.toastr_top_center {
  top: 12px;
  right: 12px;
  width: 100%;
}

.toastr_bottom_center {
  bottom: 12px;
  right: 12px;
  width: 100%;
}

.toastr_top_left {
  top: 12px;
  left: 12px;
}

.toastr_top_right {
  top: 12px;
  right: 12px;
}

.toastr_bottom_right {
  right: 12px;
  bottom: 12px;
}

.toastr_bottom_left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container>div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #FFF;
  opacity: .8;
  filter: alpha(opacity=80);
}

#toastr_top_left,
#toastr_top_right,
#toastr_top_center,
#toastr_bottom_left,
#toastr_bottom_right,
#toastr_bottom_center {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}

#toastr_top_left>div,
#toastr_top_right>div,
#toastr_top_center>div,
#toastr_bottom_left>div,
#toastr_bottom_right>div,
#toastr_bottom_center>div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #FFF;
  opacity: .8;
  font-size: 14px;
  filter: alpha(opacity=80);
}

#toast-container>div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}

#toastr_top_left>div:hover,
#toastr_top_right>div:hover,
#toastr_top_center>div:hover,
#toastr_bottom_left>div:hover,
#toastr_bottom_right>div:hover,
#toastr_bottom_center>div:hover,
#toast-container>div:hover {
  -moz-box-shadow: 0 0 12px #000;
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000;
  opacity: 1;
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container.toastr_bottom_center>div,
#toast-container.toastr_top_center>div,
#toastr_bottom_center.toastr_bottom_center>div,
#toastr_top_center.toastr_top_center>div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

#toastr_bottom_right .toast-success,
#toastr_bottom_center .toast-success,
#toastr_bottom_left .toast-success,
#toast-container .toast-success,
#toastr_top_left .toast-success,
#toastr_top_right .toast-success,
#toastr_top_center .toast-success {
  background-color: theme-color-main(success);
  background-image: none;
  color: #fff;
}

#toastr_bottom_right .toast-error,
#toastr_bottom_center .toast-error,
#toastr_bottom_left .toast-error,
#toastr_top_left .toast-error,
#toastr_top_right .toast-error,
#toastr_top_center .toast-error,
#toast-container .toast-error {
  background-color: theme-color-main(danger);
}

#toastr_bottom_right .toast-info,
#toastr_bottom_center .toast-info,
#toastr_bottom_left .toast-info,
#toast-container .toast-info,
#toastr_top_right .toast-info,
#toastr_top_center .toast-info,
#toastr_top_left .toast-info {
  background-color: theme-color-main(primary);
}

#toastr_bottom_right .toast-warning,
#toastr_bottom_center .toast-warning,
#toastr_bottom_left .toast-warning,
#toast-container .toast-warning,
#toastr_top_left .toast-warning,
#toastr_top_right .toast-warning,
#toastr_top_center .toast-warning {
  background-color: theme-color-main(warning);
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: .4;
  filter: alpha(opacity=40);
}

@media all and (max-width:220px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container>div.rtl {
    padding: 8px 50px 8px 8px
  }
  #toast-container .toast-close-button {
    right: -.2em;
    top: -.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em;
  }
}

@media all and (min-width:241px) and (max-width:480px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container>div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -.2em;
    top: -.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em;
  }
}

@media all and (min-width:481px) and (max-width:768px) {
  #toast-container>div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
  #toast-container>div.rtl {
    padding: 15px 50px 15px 15px;
  }
}

/* ============================ */
/*            End Toastr
/* ============================ */