/* ============================ */
/*            Grid
/* ============================ */

.grid-col {
  padding: 10px 15px;
  background: #f9f9f9;
  margin-bottom: 30px;
  color: #666;
  border: 1px solid #e3e6e8;
}

/* ============================ */
/*            End Grid
/* ============================ */