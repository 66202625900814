/* ============================ */
/*            Profile
/* ============================ */

.profile_page {
  .card {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}

.card-header {
  padding: 20px 25px;
  background-color: #fff;
}

.rounded-card img,
.user-img img {
  margin: 0 auto;
  display: block;
  width: 100%
}

.user-img {
  img {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19)
  }
}

.follow-btn button:first-child,
.user-about i {
  margin-right: 10px
}

.card-block {
  padding: 1.25rem;
}

.card-block.user-info {
  position: absolute;
  width: 100%;
}

.profile-bg-img {
  width: 100%;
  height: 195px;
  background-size: cover !important;
  background-position: center center !important;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
  }
}

.user-title {
  position: relative;
  bottom: -50px;
  h2 {
    color: #fff;
    text-shadow: 1px 1px 4px #373a3c;
    font-size: 20px;
    margin-bottom: 0;
  }
}

.profile-image {
  img {
    border: 4px solid #fff
  }
}

.user-info .media-body,
.user-info .media-left {
  display: table-cell;
  vertical-align: middle
}

.cover-btn {
  bottom: 15px;
  right: 35px;
  position: absolute
}

.cover-profile .profile-bg-img {
  margin-bottom: 25px
}

.groups-contact {
  span {
    float: right;
    h4 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width:768px) {
  .wizard>.steps>ul>li {
    float: inherit;
    width: 100%
  }
  figure.effect-steve h2 {
    margin-top: 0
  }
  .cover-profile .profile-bg-img {
    height: 305px;
  }
  .cover-btn {
    float: none !important;
  }
  .card-block.user-info {
    position: inherit;
    text-align: center;
    margin-bottom: 20px
  }
  .user-info .media-left,
  .user-info .media-body {
    display: block
  }
  .cover-btn {
    position: inherit;
    float: none
  }
  .cover-btn .btn:first-child {
    margin-right: 10px
  }
  .user-info .user-title {
    position: initial;
    margin-bottom: 10px;
    margin-top: 10px
  }
}


/* ============================ */
/*            End Profile
/* ============================ */