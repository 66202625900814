.internal-calendar {

  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .month-header {
    font-size: $sizeFontNormal;
    text-align: center;
    color: #000000cc;
  }

  .calendar-container {
    padding: 10px 100px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .day-row {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .cell {
        color: #000000cc;
        font-size: $sizeFontNormal;
        transition-duration: $transitionDurationNormal;
        height: 100%;
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    }

    .calendar-row {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      //background-color: $colorMainDiffused25;

      .cell {
        font-size: $sizeFontNormal;
        padding-left: 4px;
        border-radius: 50px;
        transition-duration: $transitionDurationNormal;
        height: 100%;
        width: 50px;
        display: flex;
        color: #000000cc;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-align: center;
      }

      .cell:hover {
        background-color: #00000033;
      }

      .cell-locked {
        font-size: $sizeFontNormal;
        padding-left: 4px;
        border-radius: 50px;
        transition-duration: $transitionDurationNormal;
        height: 100%;
        width: 50px;
        display: flex;
        color: #00000088;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-align: center;
      }

      .cell-locked:hover {
        background-color: #00000033;
      }
    }
  }
}

.cell-core {
  padding-left: 4px;
  border-radius: 50px;
  transition-duration: $transitionDurationNormal;
  height: 100%;
  width: 50px;
  display: flex;
  color: #000000cc;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.cell-core:hover {
  background-color: #00000033;
}